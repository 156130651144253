<template>
  <div>
    <div class="justify-content-around pricing" id="pricing">
      <h2 class="text-center my-5 text-uppercase">Formules</h2>
      <PricingCard
        v-for="(plan, index) in plans"
        :plan="plan"
        :key="index"
        @subscribe="subscribe($event)"
      />
    </div>
  </div>
</template>
<script>
import PricingCard from "../components/PricingCard.vue";
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "Pricing",
  components: {
    PricingCard,
  },
  metaInfo() {
    if (this.$route.name === "Pricing") {
      return {
        title:
          "Edays - Agir contre déforestation - comment aider l’environnement",
        meta: [
          {
            name: "title",
            content:
              "Edays - Agir contre déforestation - comment aider l’environnement",
          },
          {
            name: "description",
            content:
              "Edays participe au reboisement des forêts sur toute la planète en plantant des arbres",
          },
          {
            property: "og:title",
            content:
              "Edays - Agir contre déforestation - comment aider l’environnement",
          },
        ],
      };
    }
  },
  data() {
    return {
      stripe: null,
      plans: [
        {
          name: "STARTER",
          value: "starter",
          pricePerMonth: 2.49,
          pricePerYear: 20.99,
          messagesPerDay: 1,
        },
        {
          name: "POWER",
          value: "power",
          pricePerMonth: 3.99,
          pricePerYear: 33.49,
          messagesPerDay: 2,
        },
        {
          name: "PREMIUM",
          value: "premium",
          pricePerMonth: 5.49,
          pricePerYear: 45.99,
          messagesPerDay: 3,
        },
      ],
    };
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
  },
  methods: {
    async subscribe(data) {
      const obj = {
        period: data.monthly ? "monthly" : "yearly",
        plan: data.plan.value,
      };
      const res = await this.$http.payment.subscribePayment(obj);
      await this.stripe.redirectToCheckout({
        sessionId: res.data.sessionId,
      });
    },
  },
};
</script>
