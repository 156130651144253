<template>
  <div class="pricing-card">
    <div class="d-flex justify-content-between align-items-center">
      <div class="w-100">
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ plan.name }}</h2>
          <toggle-button
            v-model="monthly"
            :width="95"
            :height="25"
            :labels="{ checked: 'MENSUEL', unchecked: 'ANNUEL' }"
            :color="{
              checked: '#0a84ff',
              unchecked: '#0a84ff',
            }"
          />
        </div>
        <p>{{ plan.messagesPerDay }} EDAYS PAR JOUR</p>
        <div class="d-flex justify-content-between">
          <div>
            <h1>{{ monthly ? plan.pricePerMonth : plan.pricePerYear }}</h1>
            <h3>
              {{ monthly ? plan.pricePerYear : plan.pricePerMonth }}
              {{ monthly ? "ANNUEL" : "MENSUEL" }}
            </h3>
          </div>
          <img src="../assets/img/euro-sign.svg" alt="" />
        </div>
      </div>
    </div>
    <button
      class="card-btn btn-pricing"
      @click="$emit('subscribe', { plan, monthly })"
    >
      <span>Adhérez</span>
    </button>
  </div>
</template>
<script>
export default {
  props: ["plan"],
  name: "PricingCard",
  data() {
    return {
      monthly: true,
    };
  },
};
</script>
